import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import "./../sass/main.scss";


import HeaderEN from "../components/HeaderEN";
import FooterEN from "../components/FooterEN";

const NotFoundPage = () => (
    <Layout>
        <HeaderEN/>
        <div className={"container-lg mt6 pt0-lg pt4"}>
            <div className={"r ac jfs jc-lg"}>
                <div className={"c my5 mwxs"}>
                    <h2 className={"py4"}>404: Page Not Found</h2>
                    <Link className={"mb5 gtm-404-back"} to="/">
                        <button>Go Back</button>
                    </Link>
                </div>
            </div>
        </div>
        <FooterEN/>
    </Layout>

)

export default NotFoundPage
